import { Button, Result } from "antd";

import Container from "@/components/container";
import { CLIENTI_PAGE } from "@/constants";
import Link from "next/link";
import PageHeader from "@/components/page-header";
import { ReactNode } from "react";
import DocumentTitle from "@/components/document-title/DocumentTitle";

export default function Custom404(): ReactNode {
  return (
    <>
      <DocumentTitle title={"Pagina non trovata"} />

      <PageHeader
        title={"Pagina non trovata"}
        breadcrumbs={[{ key: "404", label: "Pagina non trovata" }]}
      />

      <Container>
        <Result
          status="404"
          title="404"
          subTitle={"Pagina non trovata"}
          extra={
            <Link passHref href={CLIENTI_PAGE}>
              <Button type="primary" style={{ float: "inherit" }}>
                Torna indietro
              </Button>
            </Link>
          }
        />
      </Container>
    </>
  );
}
