import { ArrowLeftOutlined, HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Skeleton, Typography } from "antd";
import React, { FC } from "react";

import Link from "next/link";
import classNames from "classnames";
import styles from "./PageHeader.module.scss";
import { useRouter } from "next/router";

interface BreadcrumbItem {
  key: string;
  href?: string;
  label?: React.ReactNode;
  loading?: boolean;
}

interface PageHeaderProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
  className?: string;
  breadcrumbs?: BreadcrumbItem[];
  onBack?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const PageHeader: FC<PageHeaderProps> = ({
  extra,
  title,
  subTitle,
  onBack,
  className,
  breadcrumbs,
}) => {
  const router = useRouter();
  const back = onBack || router.back;

  return (
    <div className={classNames(styles.Header, className)}>
      <div className={styles.Heading}>
        <div className={styles.Left}>
          <Button
            className={styles.Back}
            icon={<ArrowLeftOutlined />}
            onClick={(e) => back(e as React.MouseEvent<HTMLButtonElement>)}
          />

          {title ? (
            <Typography.Title level={1} className={styles.Title}>
              {title}
            </Typography.Title>
          ) : (
            <Skeleton.Input
              active
              size="large"
              className={styles.SkeletonTitle}
            />
          )}

          {subTitle && (
            <Typography.Text className={styles.Subtitle}>
              {subTitle}
            </Typography.Text>
          )}
        </div>

        {breadcrumbs && (
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>

            {breadcrumbs.map(({ href, key, loading, label }) => (
              <Breadcrumb.Item key={key}>
                {loading ? (
                  <Skeleton.Input
                    active
                    size="small"
                    className={styles.BreadcrumbLoading}
                  />
                ) : href ? (
                  <Link href={href}>{label}</Link>
                ) : (
                  label
                )}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
      </div>

      <div>{extra}</div>
    </div>
  );
};

export default PageHeader;
